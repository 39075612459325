var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-container"},[_c('div',[_vm._m(0),_c('div',{staticClass:"home-body"},[_vm._m(1),_c('div',{staticClass:"titleValue"},[_vm._v(_vm._s(_vm.roomInfo.hospitalName)+"|"+_vm._s(_vm.roomInfo.bunkNo))]),_c('div',{staticClass:"box"},[_c('img',{staticClass:"icons",attrs:{"src":require("../../../assets/home/phone.png"),"alt":""}}),_c('span',{staticClass:"titleName"},[_vm._v("联系电话"),_c('span',{staticClass:"titleValue"},[_vm._v(_vm._s(_vm.userInfo.userPhone))])])])]),_c('div',{staticClass:"home-menu"},[_vm._m(2),_c('div',{staticClass:"menuBoxGo"},[_c('span',{staticClass:"order"},[_vm._v("线上订餐，送餐上门")]),_c('div',{staticClass:"orderBox",on:{"click":_vm.goToMenu}},[_c('span',{staticClass:"goOrder"},[_vm._v("去订餐")]),_c('img',{staticClass:"orderImg",attrs:{"src":require("../../../assets/home/right.png"),"alt":""}})])])]),_vm._m(3)]),_c('div',{staticClass:"page-bottom-nav"},[_c('van-tabbar',{attrs:{"active-color":"#000000","inactive-color":"#767676"},model:{value:(_vm.activeNav),callback:function ($$v) {_vm.activeNav=$$v},expression:"activeNav"}},[_c('van-tabbar-item',{attrs:{"icon":"home-o"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
                ? require('@/assets/mHomeActive.png')
                : require('@/assets/mHome.png')}})]}}])},[_c('span',[_vm._v("首页")])]),_c('van-tabbar-item',{attrs:{"icon":"search"},on:{"click":_vm.goToOrder},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
                ? require('@/assets/mOrderActive.png')
                : require('@/assets/mOrder.png')}})]}}])},[_c('span',[_vm._v("订单")])]),(!_vm.isApk)?_c('van-tabbar-item',{attrs:{"icon":"friends-o"},on:{"click":_vm.goToPersonal},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
                ? require('@/assets/mPersonalActive.png')
                : require('@/assets/mPersonal.png')}})]}}],null,false,4241999017)},[_c('span',[_vm._v("个人中心")])]):_vm._e()],1)],1),_c('van-popup',{attrs:{"close-on-click-overlay":false},model:{value:(_vm.showAggrement),callback:function ($$v) {_vm.showAggrement=$$v},expression:"showAggrement"}},[_c('div',[_c('div',{staticClass:"contentPro"},[_c('div',{staticClass:"title"},[_vm._v("请选择：")]),_c('div',[_c('van-form',{attrs:{"validate-first":""},on:{"failed":_vm.onFailed}},[_c('van-radio-group',{attrs:{"direction":"horizontal","required":"","name":"relation"},model:{value:(_vm.relation),callback:function ($$v) {_vm.relation=$$v},expression:"relation"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("家属订餐")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("患者本人")])],1)],1)],1)]),_c('div',{staticClass:"page-bottom-btn"},[_c('van-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.onFailed}},[_vm._v("确认")])],1)])]),_c('van-popup',{attrs:{"close-on-click-overlay":false},model:{value:(_vm.showUserInfo),callback:function ($$v) {_vm.showUserInfo=$$v},expression:"showUserInfo"}},[_c('div',{staticStyle:{"font-size":"14px","color":"#646566","padding":"0.6rem 3rem 0"}},[_c('div',{staticStyle:{"margin-bottom":"18px","margin-top":"14px"}},[_vm._v("请输入患者信息：")]),_c('div',[_c('van-form',{attrs:{"validate-first":""},on:{"submit":_vm.onSubmit}},[_c('van-field',{staticStyle:{"height":"3.5rem"},attrs:{"name":"患者姓名","label":"","placeholder":"请输入患者姓名","rules":[{ required: true, message: '患者姓名不能为空' }]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('van-field',{staticStyle:{"height":"4.5rem"},attrs:{"type":"tel","name":"患者手机号","label":"","placeholder":"请输入患者手机号","rules":[
                  { required: true, message: '患者手机号不能为空' },
                  { message:'手机号格式错误', pattern:new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)}
                ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('div',{staticClass:"page-bottom-btn"},[_c('van-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"large","native-type":"submit"}},[_vm._v("确认")])],1)],1)],1)])]),_c('van-popup',{attrs:{"close-on-click-overlay":false},model:{value:(_vm.showPhone),callback:function ($$v) {_vm.showPhone=$$v},expression:"showPhone"}},[_c('div',{staticStyle:{"font-size":"14px","color":"#646566","padding":"0.6rem 3rem 0"}},[_c('div',{staticStyle:{"margin-bottom":"18px","margin-top":"14px"}},[_vm._v("请输入患者信息：")]),_c('div',[_c('van-form',{attrs:{"validate-first":""},on:{"submit":_vm.onSubmitPhone}},[_c('van-field',{staticStyle:{"height":"4.5rem"},attrs:{"type":"tel","name":"患者手机号","label":"","placeholder":"请输入患者手机号","rules":[
                  { required: true, message: '患者手机号不能为空' },
                  { message:'手机号格式错误', pattern:new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)}
                ]},model:{value:(_vm.apkPhone),callback:function ($$v) {_vm.apkPhone=$$v},expression:"apkPhone"}}),_c('div',{staticClass:"page-bottom-btn"},[_c('van-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"large","native-type":"submit"}},[_vm._v("确认")])],1)],1)],1)])]),_c('van-popup',{attrs:{"close-on-click-overlay":false},model:{value:(_vm.showUserChoose),callback:function ($$v) {_vm.showUserChoose=$$v},expression:"showUserChoose"}},[_c('div',{staticStyle:{"font-size":"14px","color":"#646566","padding":"0.6rem 3rem 0"}},[_c('div',{staticStyle:{"margin-bottom":"13px","margin-top":"14px"}},[_vm._v("请选择患者")]),_c('div',[_c('van-form',{attrs:{"validate-first":""},on:{"submit":_vm.onSubmitUser}},[_c('van-radio-group',{staticStyle:{"max-height":"20vh","overflow":"auto"},attrs:{"required":"","name":"user"},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}},_vm._l((_vm.userList),function(item,index){return _c('van-radio',{key:index,staticStyle:{"padding":"5px 0"},attrs:{"name":item}},[_vm._v(" "+_vm._s(item.xingMing)+" ")])}),1),_c('div',{staticClass:"page-bottom-btn"},[_c('van-button',{staticStyle:{"width":"100%","margin-top":"10px"},attrs:{"type":"primary","size":"large","native-type":"submit"}},[_vm._v("确认")])],1)],1)],1)])]),(_vm.loading)?_c('van-loading',{attrs:{"size":"24px","vertical":""}},[_vm._v("正在验证信息，请稍候...")]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-bg"},[_c('img',{staticClass:"bgimg",attrs:{"src":require("../../../assets/home/home_bg.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('img',{staticClass:"icons",attrs:{"src":require("../../../assets/home/map.png"),"alt":""}}),_c('span',{staticClass:"titleName"},[_vm._v("当前订餐位置")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"memuBox"},[_c('span',{staticClass:"serve"},[_vm._v("订餐服务")]),_c('img',{staticClass:"serveImg",attrs:{"src":require("../../../assets/home/clock.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-logo"},[_c('img',{staticClass:"logoimg",attrs:{"src":require("../../../assets/home/logo.png"),"alt":""}})])
}]

export { render, staticRenderFns }