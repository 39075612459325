<template>
  <div class="home-container">
    <div>
      <div class="home-bg">
      <img src="../../../assets/home/home_bg.png" alt="" class="bgimg" />
    </div>
      <div  class="home-body">
        <div class="box">
          <img src="../../../assets/home/map.png" alt="" class="icons">
          <span class="titleName">当前订餐位置</span>
        </div>
        <!-- <div class="titleValue">{{roomInfo.hospitalName}}|{{roomInfo.floorMsg}}|{{roomInfo.bunkNo}}</div> -->
        <div class="titleValue">{{roomInfo.hospitalName}}|{{roomInfo.bunkNo}}</div>
        <div  class="box">
          <img src="../../../assets/home/phone.png" alt="" class="icons">
          <span class="titleName">联系电话<span class="titleValue">{{userInfo.userPhone}}</span></span>
        </div>
        <!-- <div class="titleName">请核对当前订餐信息，如有问题请至个人中心进行修改！</div> -->
      </div>
      <div class="home-menu">
        <div class="memuBox">
          <span class="serve">订餐服务</span>
          <img src="../../../assets/home/clock.png" alt="" class="serveImg" />
        </div>
        <div class="menuBoxGo">
          <span class="order">线上订餐，送餐上门</span>
          <div class="orderBox" @click="goToMenu">
            <span class="goOrder">去订餐</span>
           <img src="../../../assets/home/right.png" alt="" class="orderImg" /> 
          </div>
          
        </div>
      </div>
      <div class="home-logo">
        <img src="../../../assets/home/logo.png" alt="" class="logoimg" />
      </div>
    </div>

    <div class="page-bottom-nav">
      <van-tabbar
        v-model="activeNav"
        active-color="#000000"
        inactive-color="#767676"
      >
        <van-tabbar-item icon="home-o">
          <span>首页</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('@/assets/mHomeActive.png')
                  : require('@/assets/mHome.png')
              "
            />
          </template>
        </van-tabbar-item>
        <van-tabbar-item icon="search" @click="goToOrder">
          <span>订单</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('@/assets/mOrderActive.png')
                  : require('@/assets/mOrder.png')
              "
            />
          </template>
        </van-tabbar-item>
        <van-tabbar-item icon="friends-o" @click="goToPersonal" v-if="!isApk">
          <span>个人中心</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('@/assets/mPersonalActive.png')
                  : require('@/assets/mPersonal.png')
              "
            />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>

    <van-popup v-model="showAggrement" :close-on-click-overlay="false">
        <div>
          <!-- <div class="titlePro">温馨提示</div> -->
          <div class="contentPro">
            <div class="title">请选择：</div>
            <div>
              <van-form validate-first @failed="onFailed">
                <van-radio-group v-model="relation" direction="horizontal" required name="relation">
                  <van-radio name="1">家属订餐</van-radio>
                  <van-radio name="2">患者本人</van-radio>
                </van-radio-group>
              </van-form>
              
            </div>
          </div>
          <div class="page-bottom-btn">
          <van-button type="primary" size="large" @click="onFailed">确认</van-button>
        </div>
        </div>
    </van-popup>

    <van-popup v-model="showUserInfo" :close-on-click-overlay="false">
          <div style="font-size: 14px;color: #646566; padding: 0.6rem 3rem 0;">
            <div style="margin-bottom:18px;margin-top:14px;">请输入患者信息：</div>
            <div>
              <van-form validate-first @submit="onSubmit">
                <van-field
                  v-model="name"
                  name="患者姓名"
                  label=""
                  placeholder="请输入患者姓名"
                  :rules="[{ required: true, message: '患者姓名不能为空' }]"
                  style="height: 3.5rem;"
                >
                </van-field>
                <van-field
                  v-model="phone"
                  type="tel"
                  name="患者手机号"
                  label=""
                  placeholder="请输入患者手机号"
                  :rules="[
                    { required: true, message: '患者手机号不能为空' },
                    { message:'手机号格式错误', pattern:new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)}
                  ]"
                  style="height: 4.5rem;"
                >
                </van-field>
                <div class="page-bottom-btn">
                  <van-button type="primary" size="large" native-type="submit" style="width: 100%;">确认</van-button>
                </div>
              </van-form>
            </div>
          </div>
    </van-popup>

    <van-popup v-model="showPhone" :close-on-click-overlay="false">
          <div style="font-size: 14px;color: #646566; padding: 0.6rem 3rem 0;">
            <div style="margin-bottom:18px;margin-top:14px;">请输入患者信息：</div>
            <div>
              <van-form validate-first @submit="onSubmitPhone">
                <van-field
                  v-model="apkPhone"
                  type="tel"
                  name="患者手机号"
                  label=""
                  placeholder="请输入患者手机号"
                  :rules="[
                    { required: true, message: '患者手机号不能为空' },
                    { message:'手机号格式错误', pattern:new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)}
                  ]"
                  style="height: 4.5rem;"
                >
                </van-field>
                <div class="page-bottom-btn">
                  <van-button type="primary" size="large" native-type="submit" style="width: 100%;">确认</van-button>
                </div>
              </van-form>
            </div>
          </div>
    </van-popup>

    <van-popup v-model="showUserChoose" :close-on-click-overlay="false">
          <div style="font-size: 14px;color: #646566; padding: 0.6rem 3rem 0;">
            <div style="margin-bottom:13px;margin-top:14px;">请选择患者</div>
            <div>
              <van-form validate-first @submit="onSubmitUser">
                <van-radio-group v-model="user" required name="user" style="max-height: 20vh; overflow: auto;">
                  <van-radio v-for="(item, index) in userList" :key="index" :name='item' style="padding: 5px 0;"> {{ item.xingMing }} </van-radio>
                </van-radio-group>
                <div class="page-bottom-btn">
                  <van-button type="primary" size="large" native-type="submit" style="width: 100%; margin-top:10px;">确认</van-button>
                </div>
              </van-form>
            </div>
          </div>
    </van-popup>

    <van-loading size="24px" vertical v-if="loading">正在验证信息，请稍候...</van-loading>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { EventBus } from '../../../EventBus';
export default {
  name: "myOrder",
  data() {
    return {
      activeNav: 0,
      showAggrement:false,
      relation: '',
      roomInfo:{
        hospitalId:'',
        hospitalName:'',
        // floorMsg:'',
        bunkNo:'',
      },
      userInfo:{
        userName:'',
        userPhone:'',
      },
      loginInfo:{
        token:'',
        openid:'',
        sessionKey:'',
      },
      access: null,
      showUserInfo: false,
      showPhone: false,
      apkPhone: '',
      showUserChoose: false,
      user: "",
      userList: [],
      phone: '',
      name:'',
      timer:null,
      loading: false,
      isApk: false,
    };
  },
  methods: {
    // // 防抖事件
    // debounce(fn, wait) {
    //     let that=this
    //     // 如果此时存在定时器，则取消之前的定时器重新记时
    //     if (that.timer) {
    //     clearTimeout(that.timer);
    //     this.timer = null;
    //      }
    //     // 设置定时器，使事件间隔指定事件后执行
    //     that.timer = setTimeout(() => {
    //     this.onSubmit()
    //     }, 2000);
    // },
    onFailed(){
      if(!this.relation){
        Dialog.alert({
          title: '提示',
          message: '请选择用户类别'
        }).then(() => {
           // on close
        });
      }else{
            console.log('当前关系是',this.relation);
            localStorage.setItem('relation', JSON.stringify(this.relation));
            //1家属  2患者
            if(this.relation=='2'){
              this.getPatientInfo()
            }else{
              this.$http.postAction("/queryUserById", {
                openid: this.loginInfo.openid,
              }).then((res) => {
                if (res.code == 200) {
                  if(res.data){
                    this.userInfo.userPhone = res.data.dianHua;
                    this.userInfo.userName = res.data.xingMing;
                    localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
                  } else{
                    this.userInfo.userPhone = '';
                    this.userInfo.userName = '';
                    localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
                    Dialog.alert({
                      title: '提示',
                      message: '请至个人中心填写姓名及手机号'
                    }).then(() => {
                      // on close
                    });
                  }
                  this.showAggrement = false;
                }else{
                  this.$toast.fail(res.msg)
                  this.showAggrement = true;
                }
              });
            }
      }
    },
    // 查询当前patient信息
    getPatientInfo(){
      this.$http.getAction("/queryUser", {
          hospitalId:this.roomInfo.hospitalId,//this.roomInfo.hospitalId '103'
          bunkNo:this.roomInfo.bunkNo,//this.roomInfo.bunkNo '103_14'
        }).then((res) =>{
          if(res.code==200){
            console.log('返回患者信息',!res.data);
            if (!res.data) {
              this.$toast.fail('当前患者信息为空，请选择家属点餐')
              this.showAggrement=true
              }else{
              this.userInfo.userPhone = res.data.dianHua
              this.userInfo.userName = res.data.xingMing
              localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
              this.$http.postAction("/userMsgBind", {
                userName: this.userInfo.userName,
                userPhone: this.userInfo.userPhone,
                openid: this.loginInfo.openid,
              }).then((res) =>{
                if(res.code==200){
                  this.showAggrement = false;
                }else{
                  this.$toast.fail(res.msg)
                  this.showAggrement = true;
                }
              })
              }
            
          }else{
            this.$toast.fail(res.msg)
            this.showAggrement=true;
          }
        })
    },
    onSubmit(){
      this.loading = true;
      // 接口 通过手机号和姓名查患者信息
      this.$http.getAction("/patientInfo", {phone: this.phone,name: this.name,}).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          if(res.data){ 
            this.roomInfo.hospitalId = res.data.yuanQuId || '';
            this.roomInfo.hospitalName = res.data.remarks || '';
            this.roomInfo.bunkNo = res.data.dangQianCw || '';
            localStorage.setItem('roomInfo', JSON.stringify(this.roomInfo));
            this.showUserInfo = false;
            this.showAggrement = true;
          } else{
            this.$toast.fail("当前无住院信息!");
          }
        }
      });
    },
    onSubmitPhone(){
      this.loading = true;
      // 接口 通过手机号查患者信息
      this.$http.getAction("/patientInfoByPhone", {phone: this.apkPhone}).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          // if(res.data){
            this.userList = res.data;
            if(this.userList.length > 1){
              this.showPhone = false;
              this.showUserChoose = true;
            } else{
              this.roomInfo.hospitalId = this.userList[0].yuanQuId || '';
              this.roomInfo.hospitalName = this.userList[0].remarks || '';
              this.roomInfo.bunkNo = this.userList[0].dangQianCw || '';
              localStorage.setItem('roomInfo', JSON.stringify(this.roomInfo));
              this.userInfo.userPhone = this.userList[0].dianHua || '';
              this.userInfo.userName = this.userList[0].xingMing || '';
              localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
              this.showPhone = false;
            }
        } else{
          this.$toast.fail(res.msg);
        }
        // }
      });
    },
    onSubmitUser(){
      this.roomInfo.hospitalId = this.user.yuanQuId || '';
      this.roomInfo.hospitalName = this.user.remarks || '';
      this.roomInfo.bunkNo = this.user.dangQianCw || '';
      localStorage.setItem('roomInfo', JSON.stringify(this.roomInfo));
      this.userInfo.userPhone = this.user.dianHua || '';
      this.userInfo.userName = this.user.xingMing || '';
      localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
      this.showUserChoose = false;
    },
    goToMenu(){
      this.$router.push({ path: "/menuList" });
    },
    // 点击跳转订单页
    goToOrder() {
      this.$router.replace({ path: "/myOrder" });
    },
    // 点击跳转个人中心页
    goToPersonal() {
      this.$router.replace({ path: "/personalCenter" });
    },
  },
  mounted() {
    // this.getPatientInfo()
    // localStorage.setItem('roomInfo',JSON.stringify(this.roomInfo))
    // localStorage.setItem('userInfo',JSON.stringify(this.userInfo))
    const currentUrl = window.location.href;
    const href = currentUrl.split('?')[0];
    const queryString = currentUrl.split('?')[1];
    console.log('??????',href);
    console.log('??????',queryString);
    if(href.indexOf('apkHome') != -1){
      // this.showAggrement = false;
      // this.showUserInfo = false;
      this.isApk = true;
      this.showPhone = true;
    } else{
      // 如果存在查询字符串，则进一步解析
      if (queryString) {
        // 将查询字符串转换为对象
        const params = {};

        queryString.split('&').forEach(param => {
          const [key, value] = param.split('=');
          // 对特殊字符进行解码
          const decodedKey = decodeURIComponent(key);
          const decodedValue = decodeURIComponent(value);
          // 将解码后的参数添加到对象中
          params[decodedKey] = decodedValue;
        });
        this.loginInfo.token = params.token || '';
        this.loginInfo.openid = params.openid || '';
        this.loginInfo.sessionKey = params.sessionKey || '';
        localStorage.setItem('loginInfo', JSON.stringify(this.loginInfo));
        this.access = params.access || 0; //0初始值 1扫码 2直接从小程序进入（非扫码进入）
        if(this.access == 1){
          // 将解析后的参数赋值给roomInfo
          this.roomInfo.hospitalId = params.hospitalId || '';
          this.roomInfo.hospitalName = params.hospitalName || '';
          // this.roomInfo.floorMsg = params.floorMsg || '';
          this.roomInfo.bunkNo = params.bunkNo || '';
          localStorage.setItem('roomInfo', JSON.stringify(this.roomInfo));
          this.showAggrement = true;
        } else if(this.access == 2){
          this.showUserInfo = true;
        }
      }else{
        // 若从其它页面返回过来则不弹出弹窗
        this.showAggrement = false;
        this.showUserInfo = false;
        this.roomInfo = JSON.parse(localStorage.getItem('roomInfo'));
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      }
    }
  }
};
</script>

<style lang="less" scoped>
.home-container{
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: PingFang SC;
  font-size: 16px;
  // font-weight: 600;
  line-height: normal;
  letter-spacing: 0em;
  .home-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 200px;
    opacity: 1;
  }
  .home-body {
    border-radius: 15px;
    width: calc(100% - 80px);
    border: 1px solid #D8D8D8;
    // min-height: 200px;
    background-color: #ffffff;
    padding: 20px 22px 20px 22px;
    position: absolute;
    top: 18vh;
    left:18px;

    .icons{
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
    .titleName{
      font-family: Source Han Sans;
      font-size: 14px;
      font-weight: normal;
      color: #3D3D3D;
      padding-left: 10px;
    }
    .titleValue{
      font-family: Source Han Sans;
      font-size: 14px;
      font-weight: bold;
      line-height: normal;
      color: #3D3D3D;
      margin-bottom: 16px;
      padding-left: 10px;
    }
    .box{
      margin-bottom: 12px;
      display: flex;
      align-items: center;
    }
  }
  .home-menu{
    border-radius: 15px;
    // border: 1px solid red;
    width: calc(100% - 80px);
    background: rgba(237, 225, 215, 0.37);
    padding: 20px 22px 20px 22px;
    position: absolute;
    top: 42vh;
    left: 18px;
    .memuBox{
      display: flex;
      justify-content: space-between;
      margin-bottom:14px;
      .serve{
        font-size: 20px;
        font-weight: bold;
        font-family: Source Han Sans;
      }
      .serveImg{
        width: 35px;
        height: 30px;
      }
    }
    .menuBoxGo{
      display: flex;
      justify-content:space-between;
      align-items: center;
      .order{
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
      }
      .orderBox{
        display: flex;
      justify-content: space-around;
      }
      .goOrder{
        font-family: DingTalk JinBuTi;
        font-size: 20px;
        font-weight: normal;
        line-height: normal;
        margin-right: 4px;
      }
      .orderImg{
        width: 26px;
        height: 26px;
      }
    }
  }
  .home-logo{
    position: absolute;
    bottom: 10vh;
    left: calc(50% - 114px);
  }
.contentPro{
  font-size: 14px;
  color: #646566;
  padding: 0.6rem 3rem;
  height: 5.75rem;
  .title{ 
    margin-bottom:18px;
    margin-top:14px;
  }
  /deep/ .van-radio__label {
    font-weight: normal;
    margin-left: 0.5rem;
    color: #3d3d3d;
    font-size: 14px;
    line-height: 1.25rem;
  }
  /deep/ .van-radio__icon--checked .van-icon {
    color: #fff;
    background-color: #FF8F1F;
    border-color: #FF8F1F;
  }
}
.page-bottom-btn{
  text-align: center;
  margin-bottom: 14px;
  /deep/.van-button--large {
    width: 72%;
    height: 3.125rem;
}
}
}
/deep/ .van-field--error .van-field__control::placeholder {
    color: #969799  !important;
    font-weight: normal;
    font-size: 14px;
    -webkit-text-fill-color: currentColor;
}
/deep/.van-field__control {
  font-size: 14px;
  color: #969799 !important;
   font-weight: normal;
}
</style>